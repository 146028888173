import { defineStore } from "pinia";
import { ref } from "vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";

export enum MODALS {
  Calendar = "calendar",
  Card = "card",
  Login = "login",
  Logout = "logout",
  EmailConfirm = "email-confirm",
  PushInstructions = "push-instructions",
  DigitalReceipt = "digital-receipt",
  CardGuide = "card-guide",
  LoyaltyProgramReminder = "loyalty-program-reminder",
  CardMergeDelay = "card-merge-delay",
  Stores = "stores",
  StoresModal = "stores-modal",
  PwaIosInstruction = "pwa-ios-instruction",
  Push = "push",
  PushInstruction = "push-instruction",
  LoyaltyProgram = "loyalty-program",
  PDN = "pdn",
  CitySelect = "city-select",
  RateSuccess = "rate-success",
  RateError = "rate-error",
  CatalogFilters = "catalog-filters",
  Share = "share",
  StorePrompt = "store-prompt",
  Products = "products",
}

export const useModalsStore = defineStore("modals", () => {
  const openedModals = ref<string[]>([]);

  const getActive = (): string | null =>
    openedModals.value.length > 0 ? openedModals.value[0] : null;

  const isOpen = (modal: MODALS | string): boolean =>
    openedModals.value.includes(modal);

  const open = (name: string, clearable?: boolean, manual?: boolean) => {
    if (import.meta.server) return;
    if (clearable) {
      openedModals.value = [name];
    } else {
      openedModals.value.unshift(name);
    }
    if (!manual) {
      scrollLock.enable();
    }
  };

  const close = (name: string) => {
    openedModals.value = openedModals.value.filter((n) => n !== name);

    if (!openedModals.value.length) {
      scrollLock.disable();
    }
  };

  const closeAll = () => {
    openedModals.value = [];
    scrollLock.disable();
  };

  return {
    getActive,
    open,
    close,
    closeAll,
    isOpen,
  };
});
